<template>
  <div id="questionPaperPreview" style="background-color: white;">
    <!-- header ----------------- -->
    <div class="customHeaderDiv mt-2" v-html="customHeader"></div><br>
    <!-- ------------------------------- -->
    <!-- questions --------------------- -->
    <b-row :class="{ 'no-border': !examSettings.enableEvaluationSchemeTableBorder }" class="common-font ml-0 mr-0 table-header common-font">
      <b-col :class="{ 'border-cell': examSettings.enableEvaluationSchemeTableBorder }" cols="1">No.</b-col>
      <b-col :class="{ 'border-cell': examSettings.enableEvaluationSchemeTableBorder }">Question</b-col>
      <b-col :class="{ 'border-cell': examSettings.enableEvaluationSchemeTableBorder }" cols="1">Mark</b-col>
      <b-col :class="{ 'border-cell': examSettings.enableEvaluationSchemeTableBorder }" cols="1">CO</b-col>
      <b-col :class="{ 'border-cell': examSettings.enableEvaluationSchemeTableBorder }" cols="1">ML</b-col>
      <b-col :class="{ 'border-cell': examSettings.enableEvaluationSchemeTableBorder }" cols="1">BL</b-col>
    </b-row>

    <div class="text-danger" v-if="loading"> Loading...</div>
    <div v-else v-for="(qORg, qgindex) in questionsAndGroupsAndSectionView" :key="qgindex" class="common-font">
      <div v-if="qORg.type == 'group'">
        <div :class="{ 'border-cell': examSettings.enableEvaluationSchemeTableBorder }" class="text-center text-info">
          Answer {{ groupObjects[qORg.id].best_of_questions }} out of {{ groupObjects[qORg.id].questions.length }}
          questions
        </div>
        <div v-for="(question, orQindex) in groupObjects[qORg.id].questions" :key="orQindex">
          <b-row :class="{ 'no-border': !examSettings.enableEvaluationSchemeTableBorder , 'avoid-break': true }" class="ml-0 mr-0">
            <b-col :class="{ 'border-cell': examSettings.enableEvaluationSchemeTableBorder }" cols="1"> {{
              question.question_no }}.</b-col>
            <b-col :class="{ 'border-cell': examSettings.enableEvaluationSchemeTableBorder }">
              <span v-for="(qData, qdIndex) in question.evaluation_scheme" :key="qdIndex" class="mr-1">
                <span class="big-font" v-if="qData.type == 'text'" v-html="latexCodeToMathLive(qData.value)"></span>
                <img class="questionPaperPreviewImg" v-if="qData.type == 'image'"
                  :src="questionAttachmentDetails(qData.value, question).url">
              </span>
            </b-col>

            <b-col style="text-align: center;" :class="{ 'border-cell': examSettings.enableEvaluationSchemeTableBorder }" cols="1"> {{
              question.maximum_mark }}</b-col>
            <b-col :class="{ 'border-cell': examSettings.enableEvaluationSchemeTableBorder,'text-center': true  }" cols="1">
              <span v-if="(question.course_outcome.length > 0)">
                {{ question.course_outcome.map(item => item.code).join(', ') }}
              </span>
            </b-col>
            <b-col style="text-align: center;" :class="{ 'border-cell': examSettings.enableEvaluationSchemeTableBorder }" cols="1">
              <span v-if="(question.module_id != null)">{{ moduleName(question.module_id) }}</span>
            </b-col>
            <b-col style="text-align: center;" :class="{ 'border-cell': examSettings.enableEvaluationSchemeTableBorder }" cols="1">
              <span v-if="(question.blooms_taxonamy_level != null)">{{ question.blooms_taxonamy_level }}</span>
            </b-col>
          </b-row>

          <!-- Subquestions -->
          <div v-if="(question.subQuestions.length > 0)">
            <div v-for="(subQuestion, subQindex) in question.subQuestions" :key="subQindex">
              <b-row :class="{ 'no-border': !examSettings.enableEvaluationSchemeTableBorder , 'avoid-break': true }" class="ml-0 mr-0">
                <b-col :class="{ 'border-cell': examSettings.enableEvaluationSchemeTableBorder }" cols="1">{{
                  subQuestion.question_no }}.</b-col>
                <b-col :class="{ 'border-cell': examSettings.enableEvaluationSchemeTableBorder }">
                  <span v-for="(qData, qdIndex) in subQuestion.evaluation_scheme" :key="qdIndex" class="mr-1">
                    <span class="big-font" v-if="qData.type == 'text'" v-html="latexCodeToMathLive(qData.value)"></span>
                    <img class="questionPaperPreviewImg" v-if="qData.type == 'image'"
                      :src="questionAttachmentDetails(qData.value, subQuestion).url">
                  </span>
                </b-col>
                <b-col style="text-align: center;" :class="{ 'border-cell': examSettings.enableEvaluationSchemeTableBorder }" cols="1"> {{
                  subQuestion.maximum_mark }}</b-col>
                <b-col :class="{ 'border-cell': examSettings.enableEvaluationSchemeTableBorder,'text-center': true  }" cols="1">
                  <span v-if="(subQuestion.course_outcome.length > 0)">
                    {{ subQuestion.course_outcome.map(item => item.code).join(', ') }}
                  </span>
                </b-col>
                <b-col style="text-align: center;" :class="{ 'border-cell': examSettings.enableEvaluationSchemeTableBorder }" cols="1">
                  <span v-if="(subQuestion.module_id != null)">{{ moduleName(subQuestion.module_id) }}</span>
                </b-col>
                <b-col style="text-align: center;" :class="{ 'border-cell': examSettings.enableEvaluationSchemeTableBorder }" cols="1">
                  <span v-if="(subQuestion.blooms_taxonamy_level != null)">{{ subQuestion.blooms_taxonamy_level
                    }}</span>
                </b-col>
              </b-row>
            </div>
          </div>
          <!-- Add OR as a row with merged borders -->
          <b-row v-if="(groupObjects[qORg.id].questions.length === 2 && orQindex === 0)"
            class="text-center font-weight-bold ml-0 mr-0"
            :class="{ 'no-border': !examSettings.enableEvaluationSchemeTableBorder }">
            <b-col :class="{ 'border-cell': examSettings.enableEvaluationSchemeTableBorder }" cols="12">OR</b-col>
          </b-row>
        </div>
      </div>

      <!-- Normal question -->
      <div v-if="qORg.type == 'question'">
        <b-row :class="{ 'no-border': !examSettings.enableEvaluationSchemeTableBorder 
        , 'avoid-break': questionObjects[qORg.id].question_no != 1  }" class="ml-0 mr-0">
          <b-col :class="{ 'border-cell': examSettings.enableEvaluationSchemeTableBorder }" cols="1">{{
            questionObjects[qORg.id].question_no }}.</b-col>
          <b-col :class="{ 'border-cell': examSettings.enableEvaluationSchemeTableBorder }">
            <span v-for="(qData, qdIndex) in questionObjects[qORg.id].evaluation_scheme" :key="qdIndex" class="mr-1">
              <span v-if="qData.type == 'text'" v-html="latexCodeToMathLive(qData.value)"></span>
              <img v-if="qData.type == 'image'" :src="questionAttachmentDetails(qData.value, questionObjects[qORg.id]).url" alt="Question Image">
            </span>
          </b-col>
          <b-col style="text-align: center;" :class="{ 'border-cell': examSettings.enableEvaluationSchemeTableBorder }" cols="1">
            {{ questionObjects[qORg.id].maximum_mark }}</b-col>
            <b-col :class="{ 'border-cell': examSettings.enableEvaluationSchemeTableBorder,'text-center': true  }" cols="1">
              <span v-if="(questionObjects[qORg.id].course_outcome.length > 0)">
                {{ questionObjects[qORg.id].course_outcome.map(item => item.code).join(', ') }}
              </span>
            </b-col>

          <b-col style="text-align: center;" :class="{ 'border-cell': examSettings.enableEvaluationSchemeTableBorder }" cols="1">
            <span v-if="(questionObjects[qORg.id].module_id != null)">{{ moduleName(questionObjects[qORg.id].module_id)
              }}</span>
          </b-col>
          <b-col style="text-align: center;" :class="{ 'border-cell': examSettings.enableEvaluationSchemeTableBorder }" cols="1">
            <span v-if="(questionObjects[qORg.id].blooms_taxonamy_level != null)">{{
              questionObjects[qORg.id].blooms_taxonamy_level }}</span>
          </b-col>
        </b-row>

        <!-- Subquestions -->
        <div v-if="(questionObjects[qORg.id].subQuestions.length > 0)">
          <div v-for="(subQuestion, subQindex) in questionObjects[qORg.id].subQuestions" :key="subQindex">
            <b-row :class="{ 'no-border': !examSettings.enableEvaluationSchemeTableBorder , 'avoid-break': true }" class="ml-0 mr-0">
              <b-col :class="{ 'border-cell': examSettings.enableEvaluationSchemeTableBorder }" cols="1">{{
                subQuestion.question_no }}.</b-col>
              <b-col :class="{ 'border-cell': examSettings.enableEvaluationSchemeTableBorder }">
                <span v-for="(qData, qdIndex) in subQuestion.evaluation_scheme" :key="qdIndex" class="mr-1">
                  <span class="big-font" v-if="qData.type == 'text'" v-html="latexCodeToMathLive(qData.value)"></span>
                  <img class="questionPaperPreviewImg" v-if="qData.type == 'image'"
                    :src="questionAttachmentDetails(qData.value, subQuestion).url">
                </span>
              </b-col>
              <b-col style="text-align: center;" :class="{ 'border-cell': examSettings.enableEvaluationSchemeTableBorder }" cols="1"> {{
                subQuestion.maximum_mark }}</b-col>
              <b-col :class="{ 'border-cell': examSettings.enableEvaluationSchemeTableBorder }" cols="1">
                <span v-if="(subQuestion.course_outcome.length > 0)">
                  {{ subQuestion.course_outcome.map(item => item.code).join(', ') }}
                </span>
              </b-col>
              <b-col style="text-align: center;" :class="{ 'border-cell': examSettings.enableEvaluationSchemeTableBorder }" cols="1">
                <span v-if="(subQuestion.module_id != null)">{{ moduleName(subQuestion.module_id) }}</span>
              </b-col>
              <b-col style="text-align: center;" :class="{ 'border-cell': examSettings.enableEvaluationSchemeTableBorder }" cols="1">
                <span v-if="(subQuestion.blooms_taxonamy_level != null)">{{ subQuestion.blooms_taxonamy_level }}</span>
              </b-col>
            </b-row>
          </div>
        </div>
      </div>
      <!-- Add SECTION as a row with merged borders -->
      <b-row v-if="qORg.type == 'section'" class="text-center font-weight-bold ml-0 mr-0"
      
        :class="{ 'no-border': !examSettings.enableEvaluationSchemeTableBorder }">
        <b-col :class="{ 'border-cell': examSettings.enableEvaluationSchemeTableBorder }" cols="12">{{
          sectionObjects[qORg.id].name }}</b-col>
      </b-row>
    </div>
  </div>
</template>

<script>

import Vue from 'vue';
import * as MathLive from 'mathlive';
Vue.use(MathLive);

export default {
  props: ["questionsAndGroupsAndSectionView", "groupObjects", "exam", "questionObjects", "sectionObjects", "examSettings"],
  async created() {
    // console.log(this.questionObjects);
    // this.questionsAndGroupsAndSectionView=this.questionsAndGroupsAndSectionViewProps,
    // this.groupObjects=this.groupObjectsProps,
    // this.exam=this.examProps,
    // this.questionObjects=this.questionObjectsProps,
    // this.sectionObjects=this.sectionObjectsProps,
    this.cloudStorageRoot = this.$store.getters.getAPIKey.userAttachments;

    this.loading = true;
    await Promise.all([
      this.getCourseModules(),
      this.ExamEvaluationSchemeCustomHeader(),
    ]);
    this.loading = false;
    await MathLive.renderMathInDocument();

    for (const key in this.questionObjects) {
      const q = this.questionObjects[key];
      q.course_outcome.forEach(co => {
        this.courseOutcomes[co.id] = co;
      });
    }
    // this.prepareMarkDistributionGraph();
  },
  components: {
  },
  data() {
    return {
      courseOutcomes: {},
      bloomTaxonomyOptions: [
        {
          value: "6",
          text: "Creating",
          description: "User information to create something new",
        },
        {
          value: "5",
          text: "Evaluating",
          description: "Examine information and make judgement.",
        },
        {
          value: "4",
          text: "Analyzing",
          description: "Take apart from known and identify relationships.",
        },
        {
          value: "3",
          text: "Applying",
          description: "Use information in a new (but similar) situation.",
        },
        {
          value: "2",
          text: "Understanding",
          description: "Grasp meaning of instructional materials.",
        },
        {
          value: "1",
          text: "Remembering",
          description: "Recall specific facts.",
        },
      ],
      // questionsAndGroupsAndSectionView:[],
      // groupObjects:[],
      // exam:{},
      // questionObjects:[],
      // sectionObjects:[],
      loading: false,
      cloudStorageRoot: '',
      courseModules: [],
      customHeader: null,
      // slot_number: '',
      // footerData: { course_admin: '', maingroup_admin: '' },

      coScoreDistribution: {},
      taxonamyScoreDistribution: {},
    };
  },
  methods: {
    latexCodeToMathLive(codeText) {
      if (codeText == null || codeText == '') return '';
      return codeText.replace(/\$\$(.*?)\$\$/g, '<math-field class="p-0" style="border: none;" readonly>$1</math-field>');
    },
    questionAttachmentDetails(attachmentId, question) {
      let file = question.files.find(item => item.id == attachmentId);
      return { id: file?.id, name: file?.file_name, url: this.cloudStorageRoot + file?.file_path + file?.file_name };
    },
    moduleName(id) {
      let module = this.courseModules.find(item => item.id === id);
      if (module) return module.module;
      return id;
    },
    async getCourseModules() {
      let courseModuleUrl = this.$store.getters.getAPIKey.getCourseModules;
      const url = courseModuleUrl.replace("course_id", this.exam.course_id);
      await this.$axios.get(url).then((response) => {
        this.courseModules = response.data;
      });
    },

    async ExamEvaluationSchemeCustomHeader() {
      const url =
        this.$store.getters.getAPIKey.mainAPI + `/exams/${this.exam.id}/evaluationscheme/customheader`;
      await this.$axios.get(url).then((response) => {
        this.customHeader = response.data;
      });
    },
  },
};
</script>

<style>
.question-propertycol-10 {
  width: 10%;
  word-break: break-all;
  text-align: center;
  border: 1px solid black;
}

.question-propertycol-20 {
  width: 20%;
  word-break: break-all;
  border: 1px solid black;
  text-align: center;
}

.question-propertycol-30 {
  width: 30%;
  word-break: break-all;
  border: 1px solid black;
  text-align: center;
}

.question-propertycol-40 {
  width: 40%;
  word-break: break-all;
  border: 1px solid black;
  text-align: center;
}

.questions-container button {
  padding: 1px !important;
}

@media print {
  .no-print {
    display: none !important;
  }

  .tempInput {
    border: none;
  }

  .questionPaperPreview {
    font-size: 14pt !important;
  }

  * {
    font-size: 14pt !important;
  }

}

.questionPaperPreviewImg {
  max-width: 100%;
  max-height: 600px;
}

.customHeaderDiv .data-tbody {
  font-size: 11pt;
  /* text-align: left; */
}

/* .data-table{
            table-layout: fixed;
            width: 660pt;
        } */
.customHeaderDiv .text-center {
  text-align: center;
}

.customHeaderDiv .header-table {
  font-size: 11pt !important;
  text-align: center;
}

.customHeaderDiv .header-table strong {
  font-size: 17pt !important;
  font-weight: 800;
}

.customHeaderDiv table {
  /* text-align: left; */
  width: 100%;
  border-collapse: collapse;
}

.customHeaderDiv table,
th,
td {
  border: 1px solid #080808;
}

.customHeaderDiv th {
  padding-bottom: 4pt;
  padding-top: 7pt;
  padding-left: 7pt;
  padding-right: 7pt;
  font-size: 12pt;
  background-color: #d7dcda;
  border: 1px solid black;
}

.customHeaderDiv table tr td {
  height: auto;
}

.customHeaderDiv .wordbreak {
  word-wrap: break-word;
}
@media print {
  .avoid-break {
    page-break-inside: avoid;
  }
}


</style>

<style scoped>
.common-font {
  font-family: 'Times New Roman';
  zoom: 1.4; /* Scales the text by 1.37 times */
}
.table-header {
    background-color: #ffffff;
    font-weight: bold;
    text-align: center;
  }
.border-cell {
  border: 0.1px solid black; 
  box-shadow: 0 0 0 0.6px #f7f2f2; 
  padding: 0px;
}
.no-border {
  border: none;
}
.customFooterDiv {
  text-align: center;
  font-weight: bold;
}
.big-font {
  font-size: larger; 
}

</style>

